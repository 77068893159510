import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges
} from '@angular/core';
import {
  AfkastDetaljer,
  AfkastLinie,
  AkkAfkastDetaljer,
  SavingsReturnPolice
} from 'ds-api';
import { openClose } from '@pfa/animations';

class ReturnLine {
  parent: ReturnLine;
  hasDetails: boolean;
  showDetails: boolean;

  constructor(public data: AfkastLinie) {}
}

@Component({
  selector: 'co-single-policy-return-table',
  styleUrls: ['./single-policy-return-table.component.scss'],
  templateUrl: './single-policy-return-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [openClose]
})
export class SinglePolicyReturnTableComponent implements OnChanges {
  @Input() savingsReturnPolicy: SavingsReturnPolice;
  @Input() returnDetail: AfkastDetaljer;
  @Input() accumulatedReturnDetail: AkkAfkastDetaljer;
  @Input() chosenYear: string;
  @Input() hideDetailsForPFAOptional = false;
  public returnLines: ReturnLine[];
  public totalReturnLines: AfkastLinie[];
  public showSavingColumn = true;
  public showCustomerCapitalReturnInfo = false;
  public expandedDetails: { [index: number]: boolean } = {};

  constructor(readonly cdRef: ChangeDetectorRef) {}

  public ngOnChanges(): void {
    this.initReturnLines();
  }

  public getProfileText(profile: string): string {
    return (
      {
        CAREFUL: 'DL.INV.C37',
        LOW: 'DL.INV.C38',
        MEDIUM: 'DL.INV.C39',
        HIGH: 'DL.INV.C40',
        G: 'DL.INV.C41',
        PFAValgfri: 'DL.INV.C377'
      }[profile] ?? ''
    );
  }

  private initReturnLines(): void {
    this.returnLines = [];
    this.totalReturnLines = [];
    this.showCustomerCapitalReturnInfo = false;
    this.showSavingColumn =
      (this.savingsReturnPolicy.type === 'Gennemsnit' &&
        !this.savingsReturnPolicy.livnetAftale) ||
      this.chosenYear !== 'DL.AF01.C105';

    const returnLinesModel =
      this.chosenYear !== 'DL.AF01.C105'
        ? this.returnDetail?.afkastLinier
        : this.accumulatedReturnDetail?.aarsAfkastChartLinier;

    let parent: ReturnLine = null;
    let child: ReturnLine = null;

    returnLinesModel?.forEach(returnLine => {
      // finding total value
      if (returnLine.contentId === 'DL.AF01.C75') {
        this.totalReturnLines.push(returnLine);
      } else {
        const returnLineObj = new ReturnLine(returnLine);
        this.returnLines.push(returnLineObj);

        // information about parents (details for one returnLine)
        if (returnLine.detalje) {
          if (!parent?.hasDetails) {
            parent.hasDetails = true;
            parent.showDetails = false;

            // you invest, by default, must be unfolded
            if (parent.data.contentId === 'DL.AF01.C71') {
              parent.showDetails = true;
            }
          }
          returnLineObj.parent = parent;
          child = returnLineObj;
        } else {
          if (child) {
            child = null;
          }
          parent = returnLineObj;
        }
      }

      if (returnLine.contentId === 'DL.AF01.C146') {
        this.showCustomerCapitalReturnInfo = true;
      }
    });
  }

  public toggleShowDetails(returnLine: ReturnLine): void {
    returnLine.showDetails = !returnLine.showDetails;
    this.cdRef.detectChanges();
  }
}
