import { Component, Input, OnChanges } from '@angular/core';
import { AfkastDetaljer, AkkAfkastDetaljer, SavingsReturnPolice } from 'ds-api';

@Component({
  selector: 'co-single-policy-return-total',
  styleUrls: ['./single-policy-return-total.component.scss'],
  templateUrl: './single-policy-return-total.component.html'
})
export class SinglePolicyReturnTotalComponent implements OnChanges {
  @Input() savingsReturnPolicy: SavingsReturnPolice;
  @Input() returnDetail: AfkastDetaljer;
  @Input() accumulatedReturnDetail: AkkAfkastDetaljer;
  @Input() hidePercentageValue = false;

  public detail: AfkastDetaljer | AkkAfkastDetaljer;

  ngOnChanges(): void {
    this.detail = this.returnDetail
      ? this.returnDetail
      : this.accumulatedReturnDetail;
  }
}
