<table
  class="pfa-table table-responsive align-right--column-2 align-right--column-3 thead-align--center spacing-unit--double-over"
>
  <thead>
    <tr>
      <th class="cell-name">&nbsp;</th>
      <th coContent="DL.AF01.C67"></th>
      <th
        *ngIf="showSavingColumn"
        coContent="DL.AF01.C69"
        [coContentSubst]="returnDetail.opsparingsDato"
      ></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let returnLine of returnLines; let index = index">
      <tr
        *ngIf="!returnLine.data.detalje || returnLine.parent?.showDetails"
        [class.compact-row]="returnLine.data.detalje"
      >
        <td class="cell-name" [attr.data-label]="'DL.AF01.C66' | content">
          <div class="flex">
            <div
              class="expandable-details"
              *ngIf="
                returnLine.data.contentId === 'DL.AF01.C70' ||
                (!hideDetailsForPFAOptional &&
                  returnLine.data.contentId === 'DL.AF01.C141')
              "
            >
              <co-icon-arrow-up
                *ngIf="expandedDetails[index]; else arrowDown"
                class="icon-fill__small--grey"
                (click)="expandedDetails[index] = !expandedDetails[index]"
              ></co-icon-arrow-up>

              <ng-template #arrowDown>
                <co-icon-arrow-down
                  class="icon-fill__small--grey"
                  (click)="expandedDetails[index] = !expandedDetails[index]"
                ></co-icon-arrow-down>
              </ng-template>
            </div>
            <span
              [coContent]="returnLine.data.contentId"
              [coContentSubst]="
                returnLine.data.contentId === 'DL.AF01.C74' ||
                returnLine.data.contentId === 'DL.AF01.C145'
                  ? returnLine.data.kundekapitalAar
                  : returnLine.data.subpolicenr
              "
            ></span>
            <co-tooltip
              [text]="returnLine.data.toolTip"
              *ngIf="returnLine.data.toolTip"
            >
            </co-tooltip>

            <ng-container *ngIf="returnLine.hasDetails">
              <co-icon-arrow-up
                class="toggle-return-lines-details"
                *ngIf="returnLine.showDetails; else arrowDownDetails"
                [matTooltip]="'DL.AF01.C94' | content"
                (click)="toggleShowDetails(returnLine)"
              ></co-icon-arrow-up>

              <ng-template #arrowDownDetails>
                <co-icon-arrow-down
                  class="toggle-return-lines-details"
                  [matTooltip]="'DL.AF01.C93' | content"
                  (click)="toggleShowDetails(returnLine)"
                ></co-icon-arrow-down>
              </ng-template>
            </ng-container>
          </div>

          <div
            *ngIf="expandedDetails[index]"
            [@openClose]
            class="expandable-details-content"
          >
            <div class="align--left" coContent="DL.AF01.C92"></div>
            <div class="grid">
              <div class="grid--item align--left" coContent="DL.AF01.C78"></div>
              <div class="grid--item align--right">
                {{
                  getProfileText(returnDetail.pfaInvesterer?.profil) | content
                }}
              </div>

              <div
                class="grid--item align--left"
                coContent="DL.AF01.C151"
              ></div>
              <div class="grid--item align--right">
                {{ returnDetail.pfaInvesterer?.klimaPlusAndelProcent || 0 }} %
              </div>

              <div class="grid--item align--left" coContent="DL.AF01.C79"></div>
              <div class="grid--item align--right">
                {{
                  (returnDetail.pfaInvesterer?.udbetalingsSikring
                    ? 'DL.AF01.C96'
                    : 'DL.AF01.C97'
                  ) | content
                }}
              </div>

              <div class="grid--item align--left" coContent="DL.AF01.C80"></div>
              <div class="grid--item align--right">
                {{
                  (returnDetail.pfaInvesterer?.kundekapital
                    ? 'DL.AF01.C96'
                    : 'DL.AF01.C97'
                  ) | content
                }}
              </div>
            </div>
          </div>
        </td>
        <td [attr.data-label]="'DL.AF01.C67' | content">
          <ng-container
            *ngIf="returnLine.data.contentId !== 'DL.AF01.C146'; else noData"
          >
            {{ returnLine.data.afkastBeloeb || 0 | currencyFormat }}
          </ng-container>
          <ng-template #noData>-&nbsp;*</ng-template>
        </td>
        <td
          *ngIf="showSavingColumn"
          [attr.data-label]="
            'DL.AF01.C69' | content: returnDetail.opsparingsDato
          "
        >
          <ng-container
            *ngIf="
              returnLine.data.opsparing || returnLine.data.opsparing === 0;
              else empty
            "
          >
            {{ returnLine.data.opsparing || 0 | currencyFormat }}
          </ng-container>
          <ng-template #empty>&nbsp;</ng-template>
        </td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot>
    <tr *ngFor="let returnLine of totalReturnLines; let index = index">
      <td class="cell-name" [attr.data-label]="'DL.AF01.C66' | content">
        <span
          [coContent]="returnLine.contentId"
          [coContentSubst]="returnLine.kundekapitalAar"
        ></span>
        <co-tooltip [text]="returnLine.toolTip" *ngIf="returnLine.toolTip">
        </co-tooltip>
      </td>
      <td [attr.data-label]="'DL.AF01.C67' | content">
        <ng-container
          *ngIf="returnLine.contentId !== 'DL.AF01.C146'; else noData"
        >
          <co-primary-number
            class="size-sm"
            [amount]="returnLine.afkastBeloeb || 0"
          ></co-primary-number>
        </ng-container>
        <ng-template #noData>-&nbsp;*</ng-template>
      </td>
      <td
        *ngIf="showSavingColumn"
        [attr.data-label]="'DL.AF01.C69' | content: returnDetail.opsparingsDato"
      >
        <ng-container
          *ngIf="returnLine.opsparing || returnLine.opsparing === 0; else empty"
        >
          <co-primary-number
            class="size-sm"
            [amount]="returnLine.opsparing || 0"
          ></co-primary-number>
        </ng-container>
        <ng-template #empty>&nbsp;</ng-template>
      </td>
    </tr>
  </tfoot>
</table>

<div
  *ngIf="showCustomerCapitalReturnInfo"
  coContent="DL.AF01.C158"
  class="spacing-unit--over"
></div>
