<div class="total-return-wrap">
  <div class="return-amount">
    <div
      class="color--primary text--bold label-note"
      coContent="DL.AF01.C155"
    ></div>

    <co-primary-number
      class="size-lg"
      data-test-id="savings-policy-details-totalSavingsReturnForYear"
      [amount]="detail?.totalAfkastForAar || 0"
    ></co-primary-number>

    <div class="label-note--text">
      <span coContent="DL.AF01.C157"></span>
      <co-tooltip [text]="'DL.AF01.I148'"></co-tooltip>
    </div>
  </div>

  <div
    *ngIf="!hidePercentageValue && savingsReturnPolicy.type === 'Marked'"
    class="return-amount"
  >
    <div
      class="color--primary text--bold label-note label-note--small"
      coContent="DL.AF01.C156"
    ></div>

    <co-primary-number
      class="size-sm"
      [amount]="detail?.totalAfkastProcentForAar || 0"
      [showDecimals]="true"
      [unit]="'%'"
    ></co-primary-number>

    <div class="label-note--text label-note--small">
      <span coContent="DL.AF01.C157"></span>
      <co-tooltip [text]="'DL.AF01.I149'"></co-tooltip>
    </div>
  </div>
</div>
